import gql from 'graphql-tag'

import {
  articlesFragment,
  detailArticleFragment,
  featuredArticlesFragment
} from './fragment.schema'

export const articlesByVerticalQuery = gql`
query articlesByVertical($vertical: [String], $preview: Boolean!, $limit: Int!, $skip: Int! ) {
  articles: guidesCollection(order: [date_DESC], preview: $preview, limit: $limit, skip: $skip, where: { vertical_in: $vertical, postType_not_in: ["Articles","Profiles"], redirectUrl_exists: false, noIndex: false, noIndex_exists: true }) {
    total
    items {
      ...articlesFragment
    }
  }
}
${articlesFragment}
`

export const detailArticleQuery = gql`
query detailArticle($vertical: String, $preview: Boolean!, $limit: Int!, $slug: String ) {
  articles: guidesCollection(order: [title_ASC], preview: $preview, limit: $limit, where: { vertical: $vertical, slug: $slug }) {
    total
    items {
      ...detailArticleFragment
    }
  }
}
${detailArticleFragment}
`

export const relatedArticlesQuery = gql`
query relatedArticles($vertical: String, $preview: Boolean!, $limit: Int!, $slug: String! ) {
  articles: guidesCollection(order: [date_DESC], preview: $preview, limit: $limit, where: { vertical: $vertical, slug_not: $slug, postType_not_in: ["Articles","Profiles"], redirectUrl_exists: false, noIndex: false, noIndex_exists: true}) {
    total
    items {
      ...articlesFragment
    }
  }
}
${articlesFragment}
`

export const featuredArticlesByVerticalQuery = gql`
query featuredArticlesByVertical($vertical: [String], $preview: Boolean!, $limit: Int!, $skip: Int! ) {
  articles: guidesCollection(order: [featuredMenuItem_ASC], preview: $preview, limit: $limit, skip: $skip, where: { vertical_in: $vertical, featuredMenuItem_in: [1,2,3,4,5], postType_not_in: ["Articles","Profiles"] }) {
    total
    items {
      ...featuredArticlesFragment
    }
  }
}
${featuredArticlesFragment}
`
