import gql from 'graphql-tag'

export const authorFragment = gql`
  fragment authorFragment on Author {
    name
    authorUrl
    authorImage {
      width
      height
      url
      title
    }
    email
    linkedin
    twitter
    authorProfile
    authorJobTitle
    faqSchema
  }
`

export const articlesFragment = gql`
  fragment articlesFragment on Guides {
    title
    subTitle
    featureImage {
      url
      description
      width
      height
    }
    author {
      name
    }
    date
    sys {
      firstPublishedAt
      publishedAt
    }
    slug
    mainContent {
      json
    }
  }
`

export const detailArticleFragment = gql`
  fragment detailArticleFragment on Guides {
    title
    subTitle
    featureImage {
      url
      description
      width
      height
    }
    author {
      name
      authorProfile
      authorImage {
        url
        width
        height
        description
      }
      authorJobTitle
      authorUrl
      vertical
      tips
    }
    date
    sys {
      firstPublishedAt
      publishedAt
    }
    slug
    template
    postType
    mainContent {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            url
            width
            height
            description
            title
          }
        }
        entries {
          block {
            sys {
              id
            }
            __typename
            ... on TextBox {
              title
              theme
              content {
                json
              }
              id
              class
            }
            __typename
            ... on InlineComponent {
              componentName
              componentClass
              customComponent
              componentName
              componentId
            }
            __typename
            ... on InlineCta {
              ctaName
              ctaTitle
              ctaSubtitle {
                json
              }
              ctaType
              ctaUrl
              ctaId
              ctaClass
              ctaTheme
            }
            __typename
            ... on KeyPoints {
              keyPointsTitle
              keyPointsList {
                json
              }
              keyPointsTheme
            }
            __typename
            ... on FaqItem {
              question {
                json
              }
              answer {
                json
              }
            }
            __typename
            ... on VideoAsset {
              name
              videoUrl
              video {
                url
              }
            }
          }
        }
      }
    }
    metaDescription
    seoTitle
    canonicalLink
    noIndex
    isIncomeProtection
    isOvhc
    hideFaqSchema
    faqSchema
    redirectUrl {
      slug
    }
    contentfulMetadata {
      tags {
        name
        id
      }
    }
  }
`

export const featuredArticlesFragment = gql`
  fragment featuredArticlesFragment on Guides {
    title
    subTitle
    featuredMenuItem,
    vertical,
    isOvhc,
    featureImage {
      url
      description
      width
      height
    }
    author {
      name
    }
    date
    sys {
      firstPublishedAt
      publishedAt
    }
    slug
    mainContent {
      json
    }
  }
`

export const categoryFragment = gql`
  fragment categoryFragment on Category {
    name
    id
    slug
    subTitle
    description
  }
`

export const mediaPostsFragment = gql`
fragment mediaPostsFragment on PressRelease {
  title
  slug
  subtitle
  author {
    name
    sys {
      id
    }
    authorProfile
    authorUrl
    authorImage {
      url
      width
      height
    }
    twitter
    linkedin
    email
    authorJobTitle
  }
  date
  featureImage {
    url
    width
    height
    description
    title
  }
  metaDescription
  seoTitle
  category
  redirectUrl {
    slug
  }
}
`

export const mediaPostFragment = gql`
fragment mediaPostFragment on PressRelease {
  title
  slug
  subtitle
  author {
    name
    sys {
      id
    }
    authorProfile
    authorUrl
    authorImage {
      url
      width
      height
    }
    twitter
    linkedin
    email
    authorJobTitle
  }
  date
  featureImage {
    url
    width
    height
    description
    title
  }
  mainContent {
    json
    links {
      assets {
        block {
          sys {
            id
          }
          url
          width
          height
          description
          title
        }
      }
      entries {
        block {
          sys {
            id
          }
          __typename
          ... on VideoAsset {
            name
            videoUrl
            video {
              url
            }
          }
        }
      }
    }
  }
  metaDescription
  seoTitle
  redirectUrl {
    slug
  }
}
`
